jQuery(function ($) {
  $(".slider").each(function () {
    $(this).slick({
      asNavFor: ".thumbnail",
      autoplay: true,
      autoplaySpeed: 5000,
      dots: false,
      slidesToShow: 1,
      centerPadding: "27.5%",
      centerMode: true,
      responsive: [
        {
          breakpoint: 510,
          settings: {
            centerPadding: "5%",
            slidesToShow: 1,
          },
        },
      ],
    });
    $(".thumbnail").slick({
      asNavFor: ".slider",
      focusOnSelect: true,
      arrows: false,
      slidesToShow: 1,
      centerPadding: "40%",
      centerMode: true,
      responsive: [
        {
          breakpoint: 510,
          settings: {
            centerPadding: "35%",
            slidesToShow: 1,
            centerMode: true,
          },
        },
      ],
    });
  });
});

$(document).ready(function () {
  // slider1 クラスが付与された要素で、まだ slick-initialized クラスが付与されていないものだけを初期化
  $(".slider1").not(".slick-initialized").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    asNavFor: ".thumbnail",
  });

  // thumbnail クラスが付与された要素で、まだ slick-initialized クラスが付与されていないものだけを初期化
  $(".thumbnail").not(".slick-initialized").slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    asNavFor: ".slider1",
    dots: false,
    centerMode: true,
    focusOnSelect: true,
  });
});
